export const contact = {
  phone: "+48 722 212 185",
  phone_simple: "+48722212185",
  phone_podologia: "+48 723 505 515",
  phone_podologia_simple: "+48723505515",
  email: "balickaclinic@int.pl",
  address: "ul. Szarych Szeregów 1/27",
  city: "37-500 Jarosław",
  week_hours: "pon-pt: 10:00 - 17:00",
  weekend_hours: "w inne dni możliwe wizyty po telefonicznym uzgodnieniu",
  facebook_link: "https://www.facebook.com/Balicka-Clinic-125097268888941/",
  instagram_link: "https://www.instagram.com/balicka_medycyna_estetyczna/",
}
