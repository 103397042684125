import React, { useState, useCallback } from "react"
import Gallery from "react-photo-gallery"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

import { DATA } from "../components/data"

const MetamorphosesPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(null)

  const { metamorphoses } = DATA

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImageIndex(index)
  }, [])

  const currentImage = metamorphoses.photos[currentImageIndex]
  const nextImage = metamorphoses.photos[currentImageIndex + 1]
  const prevImage = metamorphoses.photos[currentImageIndex - 1]

  return (
    <Layout>
      <SEO
        title={`Metamorfozy naszych klientek`}
        description={`Metamorfozy naszych klientek. Klinkij, aby zobaczyć zdjęcia.`}
      />

      <div className="team">
        <div className="container">
          <div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h1 className="offer-item-title">Metamorfozy</h1>

                <Gallery photos={metamorphoses.photos} onClick={openLightbox} direction={'column'} />
              </div>
            </div>
          </div>
          {currentImageIndex !== null && (
            <Lightbox
              mainSrc={currentImage?.src}
              nextSrc={nextImage?.src}
              prevSrc={prevImage?.src}
              onMoveNextRequest={() => setCurrentImageIndex(currentImageIndex + 1)}
              onMovePrevRequest={() => setCurrentImageIndex(currentImageIndex - 1)}
              onCloseRequest={() => setCurrentImageIndex(null)}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default MetamorphosesPage
