import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Toksyna botulinowa`}
        description={`Toksyna botulinowa to obecnie najskuteczniejsza metoda niwelowania zmarszczek mimicznych. Od kilku lat jeden z najpopularniejszych zabiegów medycyny estetycznej na świecie.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Toksyna botulinowa</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Toksyna botulinowa to obecnie najskuteczniejsza metoda
                niwelowania zmarszczek mimicznych. W rękach doświadczonego
                lekarza jest lekiem bezpiecznym. Od kilku lat jeden z
                najpopularniejszych zabiegów medycyny estetycznej na świecie. Ma
                niezwykle szerokie zastosowanie – stosowana w leczeniu migreny,
                bruksizmu (zgrzytania zębami) oraz nadmiernej potliwości. W
                medycynie estetycznej stosuje się ją przede wszystkim jako
                element profilaktyki antystarzeniowej.  Pozwala szybko,
                skutecznie i na długi czas spłycić zmarszczki.{" "}
              </p>
              <p className="offer-item-description">
                Toksyna botulinowa typu A to bardzo silnie działająca naturalna
                neurotoksyna (białko)powodującą zahamowanie uwalniania
                acetylocholiny – neuroprzekaźnika odpowiedzialnego za
                przekaźnictwo impulsów między nerwami a mięśniami. Toksyna
                botulinowa działa poprzez blokowanie połączeń między
                zakończeniami nerwów, a unerwianymi przez nie mięśniami, czyli
                blokuje tzw. synapsy nerwowo – mięśniowe. Prowadzi to do
                czasowej, odwracalnej blokady skurczu mięśni oraz do czasowego,
                odwracalnego zahamowania wydzielania przez niektóre gruczoły
                skóry. Brak możliwości kurczenia się porażonych mięśni (tam,
                gdzie została wstrzyknięta toksyna botulinowa), prowadzi do ich
                przejściowego i odwracalnego rozluźnienia . Blokada czynności
                mięśni następuje w okresie od 24 godzin do 7 dni po zabiegu.
                Powrót funkcji mięśni – kurczliwości trwa około 3-6 miesięcy,
                zależnie od uwarunkowań osobniczych pacjenta.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Wskazania</p>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>usuwanie/redukcja zmarszczek mimicznych</li>
                  <li>
                    zmarszczki pionowe gładzizny czoła, tzw. "lwia zmarszczka"
                  </li>
                  <li>
                    zmarszczki zewnętrznych kącików oczu, tzw. "kurze łapki"
                  </li>
                  <li>zmarszczki poprzeczne czoła</li>
                  <li>leczenie nadpotliwości pach</li>
                  <li>
                    leczenie bruksizmu (zgrzytanie zębami) – wstrzykuje się go w
                    tym przypadku w mięśnie żuchwy, co redukuje ich siłę do
                    potrzebnego minimum i ogranicza wzmożoną pracę, efektem
                    “ubocznym” jest wysmuklenie linii żuchwy
                  </li>
                  <li>nadpotliwość innych okolic (czoło, stopy, dłonie)</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Efekty</p>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Twarz po zabiegu przybiera wygląd wyraźnie wypoczętej I
                zrelaksowanej. Wbrew potocznej wiedzy, nie zmieniamy rysów
                pacjenta oraz nie pozbawiamy go mimiki, a uzyskujemy efekt
                odmłodzenia twarzy. Wykonanie zabiegów z użyciem toksyny
                botulinowej daje najkorzystniejsze efekty w połączeniu z innymi
                zabiegami z zakresu medycyny estetycznej takimi jak mezoterapia,
                pellingi, czy użycie wypełniaczy.  Zzabieg praktycznie
                bezbolesny polega na ostrzykiwaniu wybranych okolic twarzy
                toksyną botulinową. Zabieg nie wymaga rekonwalescencji.
              </p>

              <div className="offer-item-description">
                <ul>
                  <li>Miejscowe infekcje skórne</li>
                  <li>Zaburzenia krzepnięcia krwi</li>
                  <li>Stosowanie niektórych leków</li>
                  <li>
                    Stwierdzone zaburzenia przewodnictwa nerwowo-mięśniowego
                  </li>
                  <li>Nadwrażliwość na składniki preparatu</li>
                  <li>Ciąża i okres karmienia piersią</li>
                  <li>
                    Po zabiegu unikamy: schylania głowy, (3 godz) ćwiczeń
                    fizycznych i sauny (przez 24 godz), solarium (osłabia
                    działanie toksyny)
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Zdjęcia</p>
              <div className="separator-line-horizontal"></div>
              <div class="offer-item-images">
                <img
                  src="../img/offer/toksyna-botulinowa/images/1.jpg"
                  alt="Zabieg toksyna botulinowa"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/toksyna-botulinowa/images/2.jpg"
                  alt="Zabieg toksyna botulinowa"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/toksyna-botulinowa/images/3.jpg"
                  alt="Zabieg toksyna botulinowa"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/toksyna-botulinowa/images/4.jpg"
                  alt="Zabieg toksyna botulinowa"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/toksyna-botulinowa/images/5.jpg"
                  alt="Zabieg toksyna botulinowa"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/toksyna-botulinowa/images/6.jpg"
                  alt="Zabieg toksyna botulinowa"
                  className="offer-item-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
