import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Medycyna regeneracyjna jest to kompleksowa regeneracja skóry`}
        description={`Skuteczna metoda cofania efektów starzenia się i przywracania skórze prawidłowych funkcji za pomocą komórek regeneracyjnych i macierzystych`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">
                Medycyna regeneracyjna - kompleksowa regeneracja skóry
              </h1>
              <div className="separator-line-horizontal"></div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Wampirzy lifting, czyli terapia osoczem bogatopłytkowym
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Wampirzy lifting to skuteczna metoda cofania efektów starzenia
                się i przywracania skórze prawidłowych funkcji za pomocą komórek
                regeneracyjnych i macierzystych pozyskiwanych z krwi pacjenta,
                posiadających nieprzecenione zdolności. Dostarczone
                do odpowiednich obszarów skóry osocze bogatopłytkowe,
                zdecydowanie poprawia jej stan i wygląd dzięki stymulacji
                komórek i wszczęciu procesów regeneracyjnych. Dochodzi
                do odbudowy i rewitalizacji w naturalny sposób wykorzystując
                skłonność organizmu do autoregeneracji, co prowadzi w efekcie
                do widocznego odmłodzenia.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Fibryna</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Fibryna jest autogenną matrycą pozyskiwaną z krwi własnej
                pacjenta. Stymuluje ona wzrost tkanek miękkich i przyspiesza
                gojenie. Poza tym obecny w niej czynniki wzrostu inicjują
                produkcję nowego kolagenu. Komórki macierzyste umiejscowione
                w sieci fibryny same znajdują uszkodzone miejsca i pobudzają ich
                regenerację. Wszystko odbywa się w sposób naturalny, przy
                wykorzystaniu skłonności organizmu do autoregeneracji.
                Zainicjowanie procesów odbudowy stanowić będzie krok
                do pozytywnych zmian, które staną się widoczne na Twojej skórze.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Dla kogo?</p>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Zabieg sprawdzi się doskonale w przypadku zmęczonej, noszącej
                znamiona starzenia skóry, także tej znajdującej się
                w delikatnych okolicach oczu czy szyi. Działanie fibryny
                zaowocuje rewitalizacją i widocznym odmłodzeniem. Zmniejszą się
                też nieestetyczne zmiany dermatologiczne, takie jak blizny
                czy przebarwienia. Poprawi się koloryt, gęstość oraz napięcie
                skóry.
                <br />
                Fibryna przysłuży się nie tylko cofnięciu zaistniałych w wyniku
                starzenia zmian, ale również profilaktyce starzenia się skóry,
                dlatego na zabieg mogą decydować się pacjenci w różnym wieku
                (zabieg zostanie dostosowany do Twoich indywidualnych potrzeb).{" "}
                <br />
                Terapię szczególnie poleca się osobom żyjącym w zgodzie
                z naturą, chcącym uzyskać naturalny efekt odmłodzenia, uczulonym
                na różne składniki stosowane w kosmetykach czy innych zabiegach
                bądź przeciwnym stosowaniu substancji pochodzenia zwierzęcego.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Zabieg łączenia fibryny strukturalnej i komórkowej</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Autologiczny zabieg z krwi własnej pacjenta, wykorzystujący
                synergię działania komórek macierzystych z fibryną strukturalną.
                Wyjątkowe połączenie dwóch frakcji w jednym zabiegi w celu
                odbudowy  tkanki skórnej.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h3 className="offer-item-title">Fibryna komórkowa</h3>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Fibryna komórkowa to koncentrat komórek macierzystych, czynników
                wzrstu oraz leukocytów,który stymuluje proces odnowy
                biologocznej rkanki skórnej, inicjując proces regeneracji. 
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h3 className="offer-item-title">Fibryna strukturalna</h3>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Fibryna strukturalna to trójwymiarowy stelaż dla nowo tworzonej
                tkanki skórnej. Odgrywa istotna rolę, jako nośnik dla komórek
                macierzystych, czynników wzrostu, cytokin i leukocytów.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">1. STYMULACJA</p>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Pobudzenie procesów namnażania sie i odkładania w stelażu
                fibrynowym grup komórkowych, niezbędnych do tworzenia nowej
                tkanki: fibroplastów, komórek naskórka i naczyń krwionośnych.
                Rozpoczyna się synteza kolagenu i białek strukturalnych
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">2. REGENERACJA</p>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Przebudowa tknaki skórnej ze wzmożoną syntezą kolagenu raz
                tworzeniem nowych naczyń kwionośnych. Kolagen przywraca skórze
                wlastyczność, sprężystość i napięcie. Nowo tworzone naczynia
                krwionośne odpowiadają za dotlenienie i odżywienie tkanki
                skórnej.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">3. ODMŁODZENIE</p>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Odbudowa prawidłowej struktury skóry porzez właściwe ukrwienie
                oraz pobudzenie kmórkowe. Następuje spowolnienie procesu
                starzenia się skóry, która staje się napieta, sprężysta,
                odzywiona i nawilżona.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Przeciwwskazania do zabiegów z osoczem i fibryną
              </h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>Choroby krwi:</li>
                  <ul className="m-b-none">
                    <li>zespoły dysfunkcji płytek</li>
                    <li>krytyczna małopłytkowość</li>
                    <li>niedobór fibrynogenu</li>
                    <li>niestabilność hemodynamiczna</li>
                    <li>posocznica</li>
                  </ul>
                  <li>Nowotwory</li>
                  <li>Przewlekłe schorzenia wątroby</li>
                  <li>Zakażenia ostre i przewlekłe</li>
                  <li>Ciąża i laktacja</li>
                  <li>Leczenie antykoagulantami</li>
                  <li>
                    Stosowanie aspiryny i innych NLPZ (niesteroidowych leków
                    przeciwzapalnych)
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h3 className="offer-item-title">
                Zabiegi z użyciem komórek macierzystych osocza (własna krew)
              </h3>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Zabiegi z użyciem komórek własnych krwi skutecznie rewitalizują
                i odżywiają delikatne tkanki warg sromowych, łechtaczki i
                przedsionka pochwy.
              </p>
              <p className="offer-item-subtitle">Dla kogo?</p>
              <p className="offer-item-description">
                Dla Pacjentek które borykają się z ciągłym bólem, pieczeniem,
                świądem okolic intymnych (nie tylko podczas stosunku, ale w
                czasie normalnej aktywności życiowej), częstymi nawracającymi
                infekcjami i podrażnieniami. Dla Pań z wiotką suchą skórą
                okolicy łechtaczki i warg sromowych.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h3 className="offer-item-title">
                Ostrzykiwanie punktu g (g - shot!)
              </h3>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Zabieg ostrzyknięcia punktu G, uchodzącego za kluczowy dla
                kobiecego orgazmu, wzmaga odczuwanie bodźców seksualnych u
                kobiet i znacząco poprawia jakość życia intymnego.
              </p>
              <p className="offer-item-description">
                Zabiegi te są praktycznie bezbolesne, wykonuje je lekarz, który
                najpierw dezynfekuje oraz miejscowo znieczula miejsce poddawane
                zabiegowi, a następnie za pomocą cienkiej igły lub kaniuli
                wprowadza preparat albo kwas hialuronowy, albo komórki
                macierzyste krwi (z pobranej wcześniej krwi od pacjentki, a
                następnie odwirowanej w specjalistycznej wirówce). Zabieg trwa
                około 30 minut.
              </p>
              <p className="offer-item-description">
                Rezultaty ostrzyknięcia są widoczne bezpośrednio po iniekcji
                preparatu, a po 7 dniach efekt staje się optymalny.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
