import React from "react"

import Layout from "../../components/layout"
import { SEO } from "../../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nie znaleziono" />
    <div className="error-page-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <h1 className="offer-item-title">Nie znaleziono</h1>
            <div className="separator-line-horizontal"></div>
            <a href="/">
              <button className="contact-button">
                Powrót do strony głównej
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
