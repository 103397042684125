/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import { DATA } from "./data"

export const SEO = ({ description, lang, meta, title, keywords }) => {
  const { site } = DATA

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || site.title}
      titleTemplate={`%s | ${site.title}`}
      meta={[
        {
          name: `description`,
          content: description || site.description,
        },
        {
          name: `google-site-verification`,
          content: `tdfhDREBk-HOxve-C63ytXklT4HQMUViNL3RHbYYgrU`,
        },
        {
          property: `og:title`,
          content: title || site.title,
        },
        {
          property: `og:description`,
          content: description || site.description,
        },
        {
          property: `og:site_name`,
          content: `Balicka Clinic`,
        },
        {
          property: `og:type`,
          content: `article`,
        },
        {
          property: `og:url`,
          content: `https://www.balicka-clinic.pl/`,
        },
        {
          property: `og:image`,
          content: `https://www.balicka-clinic.pl/img/logo.svg`,
        },
        {
          property: `twitter:card`,
          content: `summary`,
        },
        {
          property: `twitter:title`,
          content: title || site.title,
        },
        {
          property: `twitter:description`,
          content: description || site.description,
        },
        {
          property: `twitter:url`,
          content: `https://www.balicka-clinic.pl/`,
        },
        {
          property: `twitter:image`,
          content: `https://www.balicka-clinic.pl/img/logo.svg`,
        },
        {
          property: `google-site-verification`,
          content: `qXAzt0ApNfnQpegenMbkEdWF_9gDUq0EUfcRmiWgLo`,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "MedicalClinic",
            "@id": "https://www.balicka-clinic.pl",
            "image": "https://www.balicka-clinic.pl/img/logo.png",
            "name": "Balicka Clinic - klinika medycyny estetycznej",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "ul. Szarych Szeregów 1/27",
              "addressLocality": "Jarosław",
              "addressRegion": "PK",
              "postalCode": "37-500",
              "addressCountry": "PL"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 50.022987,
              "longitude": 22.663154
            },
            "url": "https://www.balicka-clinic.pl",
            "telephone": "+48722212185",
            "priceRange": "$$",
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
                ],
                "opens": "9:00",
                "closes": "16:00"
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Saturday",
                "opens": "10:00",
                "closes": "15:00"
              }
            ],
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+48-722-212-185",
              "contactType": "Zapisy"
            }
          }
      `}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}
