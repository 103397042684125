import React from "react"

import Layout from "../../components/layout"
import { SEO } from "../../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Powiększanie ust kwasem hialuronowym, natychmiastowy efekt`}
        description={`Powiększanie ust za pomocą kwasu hialuronowego. Nawilżanie, ujędrnianie, korygowanie asymetrii, podnoszenie kącików ust. Mała inwazyjność i szybkie efekty.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">
                Modelowanie i powiększanie ust z użyciem kwasu hialuronowego
              </h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Powiększanie ust kwasem hialuronowym jest jednym z częściej
                wykonywanych zbiegów w naszym gabinecie. Współczesne kanony
                piękna promują pełne, zadbane i nawilżone usta, które stają się
                synonimem zmysłowości i nadają twarzy wyrazistość. Za pomocą
                zastrzyku można również skorygować asymetrię warg, wyrównać ich
                koloryt, nadawać pożądany kształt, unieść opadające kąciki ust,
                zniwelować drobne zmarszczki. Dostosowanie działań
                do indywidualnych potrzeb i preferencji pacjenta pozwala uniknąć
                efektu przerysowania.
              </p>
              <p className="offer-item-description">
                Kwas hialuronowy ma właściwości wiązania wody, dlatego preparaty
                na jego bazie znacząco zwiększają objętość ust, dzięki czemu
                można nadać im wymarzony kształt i poprawić kontury. Substancja
                stymuluje też komórki do produkcji naturalnego kolagenu i
                poprawia ich nawilżenie.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Jak przebiega zabieg powiększania ust?
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Zabieg trwa tylko kilkanaście minut i po właściwym znieczuleniu
                jest niezbyt bolesny. Kwas hialuronowy wstrzykuje się za pomocą
                cienkiej igły lub kaniuli. Następnie rozprowadza się preparat po
                całym obszarze i delikatnie modeluje usta.
              </p>
              <p className="offer-item-description">
                Efekty estetyczne będą widoczne od razu. Dzięki zabiegowi usta
                nabiorą zmysłowego charakteru, będą bardziej atrakcyjne
                i ponętne. Bezpośrednio po aplikacji oraz przez kilka kolejnych
                dni może utrzymywać się obrzęk. Zalecamy wtedy stosować ciepłe
                okłady, smarować usta maścią z witaminą E oraz żelem z arniką.
                Po kilku dniach usta wracają do pierwotnie nadanego kształtu.
                Efekty wypełnienia mogą utrzymywać się do 12 miesięcy.
              </p>
              <p className="offer-item-description">
                Zachęcamy do powiększania ust w oparciu o naszą autorską metodę
                Glow Lips, która działa kompleksowo – nawilża, wygładza,
                wypełnia, liftinguje i podbija kolor.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Zdjęcia</p>
              <div className="separator-line-horizontal"></div>
              <div class="offer-item-images">
                <img
                  src="../../img/offer/wypelniacze/images/1.jpg"
                  alt="Zabieg wypełniacze"
                  className="offer-item-img"
                />
                <img
                  src="../../img/offer/wypelniacze/images/2.jpg"
                  alt="Zabieg wypełniacze"
                  className="offer-item-img"
                />
                <img
                  src="../../img/offer/wypelniacze/images/3.jpg"
                  alt="Zabieg wypełniacze"
                  className="offer-item-img"
                />
                <img
                  src="../../img/offer/wypelniacze/images/4.jpg"
                  alt="Zabieg wypełniacze"
                  className="offer-item-img"
                />
                <img
                  src="../../img/offer/wypelniacze/images/5.jpg"
                  alt="Zabieg wypełniacze"
                  className="offer-item-img"
                />
                <img
                  src="../../img/offer/wypelniacze/images/6.jpg"
                  alt="Zabieg wypełniacze"
                  className="offer-item-img"
                />
                <img
                  src="../../img/offer/wypelniacze/images/7.jpg"
                  alt="Zabieg wypełniacze"
                  className="offer-item-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
