import React from "react"

import Layout from "../../components/layout"
import { SEO } from "../../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Wypełniacze`}
        description={`Zabieg z użyciem kwasu hialuronowego przeznaczony jest dla osób, chcących poprawić elastyczność i nawilżenie swojej skóry. Stosowany jest również w celu naturalnego wypełnienia zmarszczek i fałd, jakie tworzą się wraz z upływem lat.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">
                Wypełniacze - kwas hialuronowy
              </h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Kwas hialuronowy naturalnie występuje w organizmie człowieka.
                Niestety jego ilość spada wraz z wiekiem (średnio 1 -2% w ciagu
                roku po 30 r. ż.), przez co skóra staje się sucha i bardziej
                wiotka. Zabieg z użyciem kwasu hialuronowego przeznaczony jest
                dla osób, chcących poprawić elastyczność i nawilżenie swojej
                skóry. Stosowany jest również w celu naturalnego wypełnienia
                zmarszczek i fałd, jakie tworzą się wraz z upływem lat.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Wskazania</p>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>
                    Przywracanie utraconej objętości twarzy oraz poprawa jej
                    zarysu, modelowanie: tzw. (wolumetria)
                  </li>
                  <li>
                    Wypełnianie zmarszczek i fałdów (m.in. wokół oczu czy fałdów
                    nosowo-wargowych, linie marionetki)
                  </li>
                  <li>Rewitalizacja skóry twarzy, szyi, dekoltu i rąk</li>
                  <li>Korekta nosa</li>
                  <li>
                    Modelowanie i{" "}
                    <a href="/wypelniacze/powiekszanie-ust">powiększanie ust</a>
                  </li>
                  <li>Uwydatnienie kości jarzmowych (policzkowych)</li>
                  <li>Przywrócenie utraconej objetości warg sromowych większych</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Efekty</p>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Efekty zabiegów z użyciem kwasu hialuronowego są bardzo
                naturalne. Zaraz po wstrzyknięciu dana okolica skóry od razu
                zwiększa swoją objętość. Tuż po zabiegu skóra może być
                obrzęknięta i zaczerwieniona, objawy te ustępują jednak od 2 do
                3 dni. W tym czasie pacjent nie jest wyłączony ze swoich
                codziennych obowiązków. Optymalny efekt widoczny jest po ok. 2-4
                tygodniach, w tym czasie cząsteczki kwasu wiążą wodę.
                <br />W związku z tym, że cząsteczki kwasu rozkładają się w
                organizmie, całościowy efekt utrzymuje się do ok. 6-12 miesięcy.
                Po tym czasie można powtórzyć zabieg.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Szczegóły zabiegu</p>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Przed zabiegiem przeprowadzany jest wywiad lekarski w celu
                ustalenia wskazań bądź przeciwskazań dotyczących zabiegu. Lekarz
                dobiera odpowiedni preparat, okolice oraz sposób podania. Na
                około 30-60 minut przed planowaną iniekcją nakładany jest krem
                miejscowo znieczulający. Zabieg nie wymaga wcześniejszej próby
                uczuleniowej. Wypełniacz mający konsystencję żelu wprowadzany
                jest do tkanek za pomocą igieł lub kaniul. Użycie kaniuli ( tępo
                zakończona igła) zmniejsza ryzyko uszkodzenia naczyń bądź
                nerwów, i związanych z tym powikłań. Igła natomiast daje
                lekarzowi możliwość większej precyzji, np. w celu wypełnienia
                pojedynczych, mniejszych zmarszczek, czy nadania ustom idealnego
                kształtu.
                <br />
                Zaraz po wstrzyknięciu kwas zaczyna wiązać wodę a skóra staje
                się bardziej elastyczna. Efektem jest powiększenie i
                wypełnienie, dzięki czemu zmarszczki stają się bardziej
                wygładzone. Kwas hialuronowy ma silne właściwości higroskopijne,
                ponieważ do jednej cząsteczki może się przyłączyć ok. 1000
                cząsteczek wody. Oznacza to, że żel „pęcznieje”, podnosząc
                skórę. Na koniec zabiegu lekarz modeluje skórę ugniatając
                znajdujący się pod nią żel. Czas utrzymania się preparatu w
                tkankach zależny jest od wieku i trybu życia pacjenta, jak
                również od mimiki, rodzaju użytego preparatu czy ekspozycji na
                promienie UV. Zanikanie wypełniacza jest korzystnym działaniem,
                ponieważ efekt jest odwracalny.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Zalety</p>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>Niskie ryzyko wystąpienia powikłań</li>
                  <li>Doskonały do wypełnienia zmarszczek</li>
                  <li>Natychmiastowe i naturalne efekty</li>
                  <li>Stymuluje komórki do produkcji kolagenu</li>
                  <li>Zabieg niechirurgiczny</li>
                  <li>Poprawia elastyczność i jędrność skóry</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <table className="table table-responsive offer-item-table">
                <colgroup>
                  <col width="50%"></col>
                  <col width="50%"></col>
                </colgroup>
                <tbody>
                  <tr>
                    <td>Ilość zabiegów potrzebnych, by uzyskać efekt</td>
                    <td className="right-cell">
                      Jeden – zabieg można powtarzać po utracie efektu
                    </td>
                  </tr>
                  <tr>
                    <td>Jak długo utrzymuje się efekt?</td>
                    <td className="right-cell">
                      W zależności od czynników indywidualnych pacjenta 6-12
                      miesięcy
                    </td>
                  </tr>
                  <tr>
                    <td>Przygotowanie do zabiegu</td>
                    <td className="right-cell">
                      Brak specjalistycznego przygotowania
                    </td>
                  </tr>
                  <tr>
                    <td>Czas trwania zabiegu</td>
                    <td className="right-cell">Ok. 15-20 minut</td>
                  </tr>
                  <tr>
                    <td>Czy zabieg jest bezbolesny?</td>
                    <td className="right-cell">
                      Tak, czasami pacjenci odczuwają niewielki dyskomfort
                    </td>
                  </tr>
                  <tr>
                    <td>Znieczulenie</td>
                    <td className="right-cell">
                      Wymaga znieczulenia miejscowego za pomocą aplikacji kremu
                    </td>
                  </tr>
                  <tr>
                    <td>Jak będę wyglądać po zabiegu?</td>
                    <td className="right-cell">
                      Zaczerwienienia, siniaki i obrzęk do ok. 2-3 dni po
                      zabiegu
                    </td>
                  </tr>
                  <tr>
                    <td>Rekonwalescencja</td>
                    <td className="right-cell">
                      Po zakończeniu zabiegu pacjent może powrócić do swojej
                      codziennej aktywności
                    </td>
                  </tr>
                  <tr>
                    <td>Główne zalecenia po zabiegu</td>
                    <td className="right-cell">
                      Przez ok. 2 tygodnie należy unikać silnego nagrzewania
                      (sauna) oraz nasłonecznienia
                    </td>
                  </tr>
                  <tr>
                    <td>Główne przeciwwskazania</td>
                    <td className="right-cell">
                      Choroby o podłożu autoimmunologicznym, skłonność do
                      powstawania blizn przyrostowych, ciąża, karmienie piersią,
                      stany zapalne skóry (opryszczka, trądzik), podrażnienia i
                      uszkodzenia skóry, nadwrażliwość na kwas hialuronowy
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Wolumetria – modelowanie owalu twarzy bez skalpela
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Za pomocą wolumetrii, czyli zabiegu liftingu i modelowania
                konturów twarzy przy użyciu kwasu hialuronowego jest możliwe
                przywrócenie utraconej objętości, właściwych rysów i owalu
                twarzy. Efekty widoczne są natychmiast po zabiegu.
              </p>
              <p className="offer-item-description">
                Wypełnianie okolicy oka (dolina łez) W nieuniknionym procesie
                starzenia się okolic oczu ogromną rolę odgrywa zanik tkanki
                podskórnej, wiotczenie skóry oraz mięśni. W wyniku zmian
                w obrębie oczodołu oraz policzków powstają  tzw. „kurze łapki”,
                czyli zmarszczki rozchodzące się promieniście od zewnętrznych
                kącików oczu. Natomiast w wyniku zapadania się skóry w okolicy
                oczu powstaje zagłębienie, (prowadzące od wewnętrznego kącika
                oka do środkowej części policzka), owo zagłębienie pacjenci
                nazywają „dołami pod oczami”, w medycynie estetycznej
                funkcjonuje określenie „doliną łez”. Każdy z nas w różnym dla
                siebie wieku zauważa pojawiające się z wiekiem zmiany. Warto
                na nie reagować zawczasu, ponieważ zmarszczki okolic oczu
                w połączeniu ze zwiotczałą i opadającą skórą dolnych powiek,
                nadają oczom i twarzy smutnego, zmęczonego wyrazu.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
