/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent"

import Header from "./header"
import Footer from "./footer"

import "../sass/main.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <Footer />
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Akceptuj"
        enableDeclineButton
        flipButtons
        declineButtonText="Odrzuć"
        cookieName="gatsby-gdpr-google-analytics"
      >
        Witamy na stronie Balicka Clinic. Nasza strona internetowa wykorzystuje
        pliki cookies (ciasteczka) w celach statystycznych.
        <div>
          <small>
            Przeczytaj politykę dotyczącą ciasteczek{" "}
            <a href="/polityka-cookies" id="cookies-eu-more">
              tutaj
            </a>
            .
          </small>
        </div>
      </CookieConsent>

      <span itemScope itemType="http://schema.org/LocalBusiness">
        <meta itemProp="name" content="Balicka Clinic" />
        <meta
          itemProp="image"
          content="https://www.balicka-clinic.pl/img/logo.svg"
        />
        <span
          itemProp="address"
          itemScope
          itemType="http://schema.org/PostalAddress"
        >
          <meta itemProp="streetAddress" content="ul. Szarych Szeregów 1/27" />
          <meta itemProp="addressLocality" content="Jarosław" />
          <meta itemProp="postalCode" content="37-500" />
        </span>
        <span itemProp="location" itemScope itemType="http://schema.org/Place">
          <meta
            itemProp="logo"
            content="https://www.balicka-clinic.pl/img/logo.svg"
          />
          <link itemProp="url" href="https://www.balicka-clinic.pl/" />
          <link
            itemProp="hasMap"
            href="https://goo.gl/maps/xge9ZP9oWTdVNnvx7"
          />
          <span
            itemProp="geo"
            itemScope
            itemType="http://schema.org/GeoCoordinates"
          >
            <meta itemProp="latitude" content="50.022987" />
            <meta itemProp="longitude" content="22.663154" />
          </span>
        </span>
        <meta itemProp="telephone" content="+48722212185" />
        <meta itemProp="openingHours" content="pon-pt 10:00-17:00" />
      </span>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
