import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const OfferPage = () => {
  return (
    <Layout>
      <SEO
        title={`Oferta kliniki Balicka Clinic w zakresie zabiegów upiększających`}
        description={`Zachęcamy do zapoznania się z ofertą Balicka Clinic. Proponujemy szeroki zakres świadczeń medycznych - upiększających, regenerujących i biostymulujących.`}
      />

      <div className="offer-list">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/biostymulatory" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/biostymulatory/thumb.png"
                    alt="Biostymulatory"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Biostymulatory</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/nici" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/nici/thumb.png"
                    alt="Nici"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Nici</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/lipoliza-iniekcyjna" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/lipoliza-iniekcyjna/thumb.png"
                    alt="Lipoliza iniekcyjna"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Lipoliza iniekcyjna</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/medycyna-regeneracyjna" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/medycyna-regeneracyjna/thumb.png"
                    alt="Medycyna regeneracyjna"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Medycyna regeneracyjna</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/wypelniacze" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/wypelniacze/thumb.png"
                    alt="Wypełniacze"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Wypełniacze</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/toksyna-botulinowa" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/toksyna-botulinowa/thumb.png"
                    alt="Toksyna botulinowa"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Toksyna botulinowa</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/mezoterapia-iglowa" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/mezoterapia-iglowa/thumb.png"
                    alt="Mezoterapia igłowa"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Mezoterapia igłowa</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/mikronakluwanie" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/mikronakluwanie/thumb.png"
                    alt="Mikronakłuwanie"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Mikronakłuwanie</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/termolifting" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/termolifting/thumb.png"
                    alt="Termolifting"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Termolifting</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/pakiet-slubny" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/pakiet-slubny/thumb.png"
                    alt="Pakiet Ślubny"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Pakiet Ślubny</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/balicka-lifting" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/balicka-lifting/thumb.png"
                    alt="Balicka Lifting"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Balicka Lifting</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/podologia" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/podologia/thumb.png"
                    alt="Podologia"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Podologia</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/terapia-swiatlem-led" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/terapia-swiatlem-led/thumb.jpg"
                    alt="Terapia światłem LED"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Terapia światłem LED</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/oczyszczanie-wodorowe-skory" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/oczyszczanie-wodorowe-skory/thumb.jpg"
                    alt="Oczyszczanie wodorowe skóry"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">
                  Oczyszczanie wodorowe skóry
                </p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/bloomea" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/bloomea/thumb.png"
                    alt="Bloomea"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Bloomea</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/radiofrekwencja" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/radiofrekwencja/thumb.jpg"
                    alt="Radiofrekwencja"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Radiofrekwencja</p>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <a href="/laseroterapia" className="cursor-pointer">
                <div className="offer-list-img-container offer-list-img">
                  <img
                    src="../img/offer/laseroterapia/thumb.jpg"
                    alt="Laseroterapia"
                    className="offer-list-img"
                  />
                  <div className="offer-list-img-overlay"></div>
                  <div className="offer-list-img-overlay-button">
                    <span>Dowiedz się więcej</span>
                  </div>
                </div>

                <p className="offer-list-img-text">Laseroterapia</p>
              </a>
            </div>

            <div className="col-md-12 col-sm-12">
              <h1 className="offer-item-title">Oferta usług Balicka Clinic</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                W gabinetach Balicka Clinic oferujemy usługi upiększające,
                regenerujące i korygujące. Wykonujemy zabiegi z użyciem toksyny
                botulinowej w zakresie usuwania zmarszczek i leczenia
                nadpotliwości. Przeprowadzamy mezoterapię igłową i{" "}
                <a href="/lipoliza-iniekcyjna">lipolizę iniekcyjną</a>, służącą
                miejscowej redukcji namiaru tłuszczu. Za pomocą kwasu
                hialuronowego wypełniamy określone partie twarzy, powiększamy
                usta i modelujemy żuchwę. Dzięki naszym preparatom stymulującym
                pobudzamy skórę Klientów do naturalnych procesów odnowy i
                regeneracji.
              </p>
              <p className="offer-item-description">
                Implantujemy nici rewitalizujące i haczykowate, które tworzą
                rusztowanie dla skóry i powodują efekt zbliżony do liftingu,
                jednocześnie zapewniając rewitalizację na poziomie komórkowym.
                Można je stosować dla ujędrniania twarzy oraz kształtowania
                części ciała, np. ujędrniania piersi. Proponujemy autorskie
                zabiegi liftingujące na twarz i szyję, stanowiące połączenie
                kwasu hialuronowego i nici jak Balicka Glow Look czy Łabędzia
                Szyja.
              </p>
              <p className="offer-item-description">
                Pomagamy kobietom w rewitalizacji miejsc intymnych, zniwelowaniu
                dolegliwości po ciąży czy porodzie siłami natury, łagodzeniu
                zmian menopauzalnych. Rozwiązujemy uciążliwy problem wysiłkowego
                nietrzymania moczu.
              </p>
              <p className="offer-item-description">
                Z przymrużeniem oka można powiedzieć, że w Balicka Clinic
                zadbają Państwo o siebie od stóp do głów. Od stóp, bo
                skorzystają Państwo z usługi <a href="/podologia">podologia</a>,
                zajmującego się leczeniem i pielęgnacją tych części ciała. Do
                głów, ponieważ wykonujemy mezoterapię skóry głowy jako kurację
                przeciwko wypadaniu włosów.
              </p>

              <h2 className="offer-item-title m-t-xlg">
                Nasz sprzęt i preparaty
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                W naszej pracy posługujemy się nowoczesnym sprzętem,
                posiadającym odpowiednie atesty i certyfikaty. Używamy laser
                frakcyjny ablacyjny CO2 oraz laser frakcyjny nieablacyjny,
                urządzenia generującego ultradźwięki Sonocare, aparatury do
                termoliftingu i systemu nakłuwania pulsującymi igłami z
                molibdenu Mikronakłuwanie. Stosujemy preparaty najnowszej
                generacji, renomowanych firm, bezpieczne dla zdrowia,
                minimalizujące ryzyko powikłań. Część zabiegów wykonujemy z
                wykorzystaniem płynów ustrojowych pacjenta, takich jak osocze
                krwi czy fibryna.
              </p>
              <p className="offer-item-description">
                Zabiegi, które przeprowadzamy w Balicka Clinic charakteryzują
                się niskim stopniem ingerencji w cielesność pacjenta. Nie wiążą
                się zatem z długą rekonwalescencją, a pacjenci niemal
                bezpośrednio po nich powracają do wykonywana codziennych
                czynności.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OfferPage
