import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Radiofrekwencja`}
        description={`Radiofrekwencja - bezoperacyjny lifting ciała i twarzy.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">
                Radiofrekwencja - bezoperacyjny lifting ciała
              </h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Urządzenie wykorzystujące radiofrekfencję(RF) oraz
                mikronakłuwanie
              </p>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                Wskazania do zabiegu:
                <ul>
                  <li>wiotkość skóry</li>
                  <li>rozstępy</li>
                  <li>nadpotliwość</li>
                  <li>blizny poparzeniowe</li>
                  <li>blizny po cięciu cesarskim</li>
                  <li>blizny pooperacyjne</li>
                </ul>
              </div>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                Przeciwwskazania:
                <ul>
                  <li>
                    Pacjenci z rozrusznikami serca,
                    karadiowerterami-defibrylatorami lub innymi wszczepionymi
                    urządzeniami elektrycznymi
                  </li>
                  <li>Kobiety w ciąży lub karmiące piersią</li>
                  <li>
                    Obecny objaw lub historia medyczna raka skóry, innych typów
                    raka i / lub brodawek przedrakowych
                  </li>
                  <li>Pacjenci w stanie krytycznym (tj. choroby serca)</li>
                  <li>
                    Upośledzony układ odpornościowy z powodu HIV, AIDS i / lub
                    leków, które uszkodziły system
                  </li>
                  <li>
                    Choroby powodujące wrażliwość na ciepło, np. Opryszczka
                  </li>
                  <li>
                    Trudne do opanowania zaburzenia endokrynologiczne, np.
                    Cukrzyca
                  </li>
                  <li>
                    Pacjenci z postępującymi ostrymi chorobami, egzemą,
                    łuszczycą, odleżynami, wysypką itp.
                  </li>
                  <li>
                    Osoby z historią utrudnionego powrotu do zdrowia po
                    chorobach skóry, keloidach i / lub urazach
                  </li>
                  <li>
                    Pacjenci z zaburzeniami krzepnięcia krwi lub którzy w ciągu
                    ostatnich 10 dni przyjmowali doustnie lub dożylnie lek
                    przeciwzakrzepowy
                  </li>
                  <li>
                    Osoby, które w ocenie lekarza nie powinny poddawać się
                    zabiegowi
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">PRZED ZABIEGIEM</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Przed zabiegiem przeprowadzana jest konsultacja z lekarzem ,
                której zadaniem jest wykluczenie przeciwwskazań oraz określenie
                najlepszej metody zabiegowej dla problemu pacjenta. Nałożenie
                kremu znieczulającego (czas znieczulenia godzina) gdyż zabieg
                może być odbierany przez niektórych pacjentów jako bolesny
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">PO ZABIEGU</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Tuż po zabiegu widoczny jest rumień i niewielki obrzęk, który
                ustępuje po około 60 minutach. Możliwe są również ledwo widoczne
                ślady na bocznej powierzchni policzka zanikające w ciągu dnia.
                Widoczne efekty bezpośrednio po zabiegu to poprawa elastyczności
                i kolorytu skóry, redukcja zmarszczek i przebarwień. Na pełen
                efekt zabiegu czeka się od 2 do 3 miesięcy.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">PRZEBIEG ZABIEGU</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Podczas zabiegu wykonywana jest seria mikro-nakłuć specjalną
                głowicą z 24 mikro-igłami emitującymi falę radiową. Lekarz
                dobiera parametry zabiegowe odpowiednio do potrzeb twojej skóry
                bądź leczonego problemu.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Radiofrekwencja mikroigłowa - na czym polega
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Urządzenie do frakcyjnej radiofrekwencji mikroigłowej RF ma
                głowicę wyposażoną w 24 mikroigieł, które po wkłuciu na
                głębokość od 0,5 do 3,5 mm podgrzewane są na całej długości. To
                bardzo ważne, bo w ten sposób podgrzewana jest nie tyle
                naskórek, co jej wszystkie warstwy. Tradycyjna radiofrekwencja
                miała (i ma nadal) z tym problem. Aby podgrzać głębsze warstwy,
                powierzchnia skóry musiała być mocno przegrzana, pomimo
                stosowania chłodzenia, poza tym trudno jest uzyskać jednolite
                podgrzanie na całej głębokości. W radiofrekwencji mikroigłowej
                wiemy dokładnie, jak głęboko i z jaką temperaturą docieramy.
                Jest to więc zabieg nie tylko efektywny, ale też precyzyjny i
                bezpieczny
              </p>
              <p className="offer-item-description">
                Efekty zabiegu RF można podzielić na te widoczne od razu oraz
                te, które uaktywniają się z czasem. Bezpośrednio po zabiegu
                następuje obkurczanie się włókien kolagenowych, przez co skóra
                staje się bardziej napięta. Jednak efekty ostateczne widoczne są
                kilka tygodni po zabiegu, nawet do 3 miesięcy po zabiegu.
                Następuje wówczas stymulacja powstawania nowego kolagenu,
                skręcania się i zagęszczania jego włókien oraz budowania między
                nimi nowych wiązań. Skóra staje się przez to bardziej jędrna i
                sprężysta.
              </p>
              <p className="offer-item-description">
                Dzięki odbudowie kolagenu wypełniają się drobne zmarszczki
                twarzy, szyi i dekoltu. To również doskonała metoda usuwania
                zmarszczek w okolicy oczu i ust, a także podnoszenia opadającej
                skóry i poprawy jej elastyczności. Ponadto zabieg skutecznie i
                trwale usuwa blizny zanikowe i niweluje rozstępy, a także na
                około pół roku redukuje nadpotliwość.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Bezoperacyjny lifting twarzy</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                To nowy zabieg medycyny estetycznej wykorzystujący frakcyjne
                fale radiowe. Podczas bezoperacyjnego liftingu przy użyciu RF
                fale wprowadzane są w skórę właściwą i w tkankę podskórną
                generując w niej ciepło i zmieniając strukturę kolagenu. Ten
                rodzaj bezoperacyjnego liftingu to innowacja w Polsce ze względu
                na użycie końcówki zaopatrzonej w 25 mikroigieł. Dzięki ich
                budowie, zabieg jest znacznie skuteczniejszy i bezpieczniejszy.
                Na końcu każdej z mikroigieł generowany jest impuls, przez co
                urządzenie oddziałuje na skórę od wewnątrz i tym samym zapobiega
                powstawaniu blizn i oparzeń powstających przy użyciu tradycyjnej
                głowicy.
              </p>
              <p className="offer-item-description">
                Natychmiastowym efektem głębokiego podgrzewania skóry właściwej
                jest rozszczepienie wiązań łączących włókna kolagenu. Proces ten
                skutkuje pobudzeniem fibroblastów, komórek odpowiedzialnych za
                syntezę kolagenu w skórze. Dochodzi wówczas do nasilonej syntezy
                nowych włókien kolagenu. Proces ten jest odpowiedzialny za
                poprawę elastyczności i jędrności skóry, a także jej ogólnego
                wyglądu i jakości.
              </p>
              <p className="offer-item-description">
                Głębokie podgrzewanie skóry właściwej i tkanki podskórnej z
                wykorzystaniem naszej RF i głowicy dwubiegunowej zaopatrzonej w
                25 mikroigieł powoduje lokalny wzrost metabolizmu i zwiększenie
                krążenia krwi. Trójglicerydy i wolne kwasy tłuszczowe zostają
                uwolnione z komórek tłuszczowych (adypocytów) do strumienia
                krwi. Powoduje to obkurczenie komórek tłuszczowych i
                zmniejszenie objętości tkanki tłuszczowej na obszarze poddanym
                terapii. Toksyny i produkty przemiany materii zostają wchłonięte
                przez limfę i wydalone w moczem. Proces powoduje zmniejszenie
                objętości i poprawę funkcjonowania tkanki tłuszczowej, a także
                wzrost sprężystości skóry.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Leczenie nadpotliwości (potrzeba 1-2 zabiegi)
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Nowatorska metoda koagulacji frakcyjnej pozwala trwale
                zlikwidować gruczoły potowe w okolicach pach, co daje możliwość
                zlikwidowania lub zminimalizowania pocenia. Pod pachami znajdują
                się gruczoły potowe, które są nieczynne u dzieci i rozwijają się
                dopiero w trakcie dojrzewania płciowego. Ich aktywność nie ma
                znaczenia dla równowagi termo cieplnej organizmu i jego
                funkcjonowania.
              </p>
              <p className="offer-item-description">
                RF to przełomowa, szybka, precyzyjna i bezpieczna procedura
                destrukcji gruczołów potowych.
              </p>
              <p className="offer-item-description">
                Zabieg polega na jednoczesnym mikronakłuwaniu i podgrzewaniu
                skóry za pomocą fal radiowych na różnych głębokościach skóry, z
                różną częstotliwością i powtórzeniami w przedziale od 0,5 do 3,5
                mm. Każdy zabieg usuwa część gruczołów potowych, dlatego już po
                pierwszym zabiegu można zaobserwować znaczącą redukcję pocenia.
                Końcowy efekt uzależniony jest od indywidualnej ilości gruczołów
                potowych oraz liczby wykonanych zabiegów. Najczęściej wykonuje
                się kurację 4 zabiegów, w odstępach około 4 tygodniowych, po
                których można spodziewać się wysokiej redukcji pocenia – aż o
                80-90%. U większości pacjentów należy spodziewać się całkowicie
                trwałego efektu, u niektórych należy wykonać co 1-2 lata zabieg
                przypominający.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Usuwanie rozstępów (1-3 zabiegi)
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Rozstępy są to ubytki w głębokich warstwach skóry,
                uwidaczniające się najbardziej na brzuchu, udach, pośladkach i
                biuście. Ubytki te powstają w wyniku zerwania włókien
                kolagenowych i elastyny. Rozstępy powstają w dwóch etapach.
              </p>
              <p className="offer-item-description">
                Zapalne to nowe czyli łatwiejsze do usunięcia oraz zanikowe,
                które do tej pory były praktycznie nie do zlikwidowania. Zerwana
                tkanka zamiast się regenerować, zanika. Problem w likwidacji
                rozstępów polega na ich głębokim położeniu oraz bardzo małej
                ilości prawidłowej tkanki w nich zawartej. Po nakłuciu do 3,5 mm
                RF wysyła energię o bardzo dużej mocy głęboko w skórę i
                stymuluje powstawanie nowego kolagenu dokładnie tam gdzie, jest
                on potrzebny, czyli w rozstępie.
              </p>
              <p className="offer-item-description">
                Daje to bardzo silny bodziec termiczny i mechaniczny stymulujący
                fibroblasty. Włókna skręcają się i zagęszczają, powstają między
                nimi nowe wiązania. W ten sposób rozstępy na nowo zaczynając się
                zapełniać i w rezultacie zanikać. Do tej pory najbardziej
                popularnymi metodami leczenia rozstępów zapalnych były peelingi,
                mikrodermabrazja, mezoterapia i laser frakcyjny. Jednak żadna z
                nich nie daje tak spektakularnych efektów jak mikroigłowy system
                RF frakcyjnych jest jak dotąd najskuteczniejszą metodą w
                leczeniu.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Środki ostrożności przed leczeniem Scarlet
              </h2>
              <div className="separator-line-horizontal"></div>

              <div className="offer-item-description">
                <ul>
                  <li>
                    Niesterydowe leki przeciwzapalne, leki przeciwzakrzepowe na
                    1 tydzień przed zabiegiem
                  </li>
                  <li>Peelingi chemiczne 1 ~ 6 tygodni przed zabiegiem</li>
                  <li>
                    Lasery LED lub nieinwazyjne na 2 ~ 3 tygodnie przed
                    zabiegiem
                  </li>
                  <li>Lasery inwazyjne na 6-12 tygodni przed zabiegiem</li>
                  <li>
                    Stosowanie preparatu Retin A i środków na bazie retinolu 1
                    miesiąc przed zabiegiem
                  </li>
                  <li>
                    Trwała lub farbowanie włosów 1 tydzień przed zabiegiem
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Postępowanie po zabiegu Scarlet RF
              </h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>
                    Po zabiegu może wystąpić zaczerwienienie, suchość skóry,
                    obrzęk, swędzenie oraz niewielki ból przez 2-3 dni
                    <br />
                    <br />
                    Rzadko mogą wystąpić ZMIANY KOLORU SKÓRY – Hiperpigmentacja
                    która przy wystawaniu się na promienie UV i przy złej
                    pielęgnacji oraz niestosowaniu się do zaleceń pozabiegowych
                    może przejść w formę przewlekłą (przebarwienie pozapalne)
                    wymagające odmiennego leczenia. W przeważającej ilości
                    przypadków zmiany hyperpigmentacyjne rozjaśniają się do
                    kilku tygodni.
                    <br />
                    <br />
                    Przez kilka dni (max 3-4 tyg) mogą utrzymywać się ślady
                    ślady po mikronakłuciach, zmiany te przy dobrej pielgnacji
                    ustępują do tygodnia, max 3-4 tygodni.
                    <br />
                    <br />
                  </li>
                  <li>
                    Zalecane jest użycie kremu nawilżającego w związku z
                    możliwym uczuciem suchości skóry (krem poleci operator w
                    czasie zabiegu np. Cicalfat)
                  </li>
                  <li>
                    Promieniowanie UV może być przyczyną występowania
                    przebarwień, w związku z tym należy stosować krew z filtrem
                    UV co 3-4 godziny
                  </li>
                  <li>
                    Należy unikać sauny oraz wysiłku fizycznego w ciągu 2
                    tygodni od dnia przeprowadzenia zabiegu. Zalecane jest
                    utrzymywanie skóry w chłodnej temperaturze np. poprzez
                    przemywanie zimną wodą.
                  </li>
                  <li>
                    Krem z filtrem przeciwsłonecznym należy nakładać na skórę
                    zarówno w pomieszczeniu jak i na zewnątrz. Zalecany czas
                    ochrony przeciwsłonecznej to minimum 2 miesiące.
                  </li>
                  <li>
                    Pomocne w rekonwalescencji jest picie dużej ilości wody
                  </li>
                  <li>
                    Po zabiegu laserem pacjent może skorzystać z innych zabiegów
                    mających na celu złagodzić i zregenerować skórę
                  </li>
                  <li>
                    Niewielkie strupy pojawiają się w ciągu 3-5 dni od zabiegu,
                    co jest naturalne dla zabiegu laserowego. Strupy samoistnie
                    odpadną w wyniku naturalnych procesów regeneracyjnych. W
                    razie zabrudzenia należy przemyć skórę Octaniseptem, a
                    następnie ponownie nałożyć krem regenerujący np. Cicalfat
                  </li>
                </ul>
              </div>
              <p className="offer-item-description">
                W przypadku wystąpienia jakichkolwiek niepokojących objawów
                należy niezwłocznie skontaktować się z operatorem który
                wykonywał zabieg.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
