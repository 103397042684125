import React from "react"

import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import { DATA } from "../../components/data"

const Page = () => {
  const { posts } = DATA
  const post = posts.find(
    (item) =>
      item.name ===
      "zabiegi-z-naruszeniem-ciaglosci-skory-tylko-u-lekarza-medycyny-estetycznej-wywiad"
  )

  return (
    <Layout>
      <SEO title={post.title} description={post.short_description} />

      <div className="post-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="post-item-title">{post.title}</h1>
              <p className="post-item-date">{post.date}</p>
              <div className="separator-line-horizontal"></div>
              <p className="post-item-description">
                Dowiedz się, dlaczego na zabiegi z przerwaniem ciągłości
                naskórka warto wybrać się do renomowanej kliniki medycyny
                estetycznej. Zapraszamy do przeczytania rozmowy z dr n. med.
                Luizą Balicką Adamik.
              </p>

              <p className="post-item-subtitle">
                <a
                  href="https://www.trustedcosmetics.pl/zabiegi-z-naruszeniem-ciaglosci-skory-tylko-u-lekarza-medycyny-estetycznej-rozmowa-z-dr-n-med-luiza-balicka-adamik/"
                  rel="noopener"
                >
                  Link do wywiadu
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
