import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Balicka Lifting`}
        description={`Specjalny, unikalny system zabiegów, który w połączeniu z dermo-kosmeceutykami odmieni w sposób spektakularny Wasz wygląd.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Balicka Lifting</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Najczęstszym problemem z jakim zgłaszacie się Panie do naszej
                kliniki jest problem opadających policzków, obwisłej skóry wokół
                ust (tzw. chomików), oraz utraty objętości i sprężystości
                starzejącej się skóry. Aby wyjść naprzeciw Waszym oczekiwaniom
                wraz z zespołem stworzyłam specjalny, unikalny system zabiegów,
                który w połączeniu z dermo-kosmeceutykami odmieni w sposób
                spektakularny Wasz wygląd.
              </p>
              <p className="offer-item-description">
                Praca przebiega etapowo, w ciągu kilku spotkań przeprowadzamy
                zabiegi głębokiego nawilżania skóry oraz biostymulacji,
                następnie stosujemy Termolifting oraz Radiofrekwencję. W trzecim
                etapie wykonujemy wolumetrię z użyciem kwasu hialuronowego, oraz
                w razie potrzeby lifting z użyciem nici haczykowych. Do wyboru
                macie Panie (i Panowie) 2 opcje
              </p>
              <div className="offer-item-description">
                <ul>
                  <li>
                    <strong>Balicka Lifting</strong> połączenie kwasu
                    hialuronowego (volumetria) termoliftingu i radiofrekwencji
                  </li>
                  <li>
                    <strong>Balicka Princess Lifting</strong> połączenie kwasu
                    hialuronowego, termoliftingu, radiofrekwencji oraz nici
                    haczykowych
                  </li>
                </ul>
              </div>
              <p className="offer-item-description">
                Do każdego programu otrzymujecie Państwo zestaw dermoceutyków z
                komórkami macierzystymi dla podtrzymania efektów kuracji.
              </p>
              <p className="offer-item-description">
                Oczywiście wszystkie te zabiegi można wykonać pojedynczo,
                jednakże jeśli zdecydujecie się Państwo na program Balicka
                Lifting oszczędzacie nawet kilka tysięcy złotych!!
              </p>
              <p className="offer-item-description">
                Jest to jedyny unikatowy program terapeutyczny łączący wiele
                technik zabiegowych i dermoceutyków, wszystko po to, aby uzyskać
                najlepszy możliwy efekt.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
