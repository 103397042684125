import { contact } from "./contact-data"
import { team } from "./team-data"
import { pricing } from "./pricing-data"
import { posts } from "./posts-data"
import { site } from "./site-data"
import { clinic } from "./clinic-data"
import { metamorphoses } from "./metamorphoses-data"

export const DATA = {
  contact,
  team,
  pricing,
  site,
  posts,
  clinic,
  metamorphoses
}
