import React from "react"

import { DATA } from "../components/data"

const Footer = () => {
  const { contact } = DATA

  return (
    <footer>
      <div className="footer" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12 footer-section">
              <p>Adres:</p>
              <p>{contact.address}</p>
              <p>{contact.city}</p>
              <p>{contact.week_hours}</p>
              <p>{contact.weekend_hours}</p>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 footer-section">
              <p>Zapisy / Infolinia:</p>
              <p>
                <a href={`tel:${contact.phone_simple}`}>
                  Medycyna estetyczna <strong>{contact.phone}</strong>
                </a>
              </p>
              <p>
                <a href={`tel:${contact.phone_podologia_simple}`}>
                  Podologia <strong>{contact.phone_podologia}</strong>
                </a>
              </p>
              <p>
                <a href={`mailto:${contact.email}`} target="_top">
                  {contact.email}
                </a>
              </p>
              <p>Numer konta: 56 1090 2590 0000 0001 4669 0634</p>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 footer-section">
              <p>Znajdź nas:</p>
              <div className="social-flex">
                <a
                  href={contact.facebook_link}
                  rel="nofollow noreferrer noopener"
                >
                  <img
                    className="img-responsive"
                    alt="Facebook"
                    src="https://www.balicka-clinic.pl/img/facebook.svg"
                  />
                </a>
                <a
                  href={contact.instagram_link}
                  rel="nofollow noreferrer noopener"
                >
                  <img
                    className="img-responsive"
                    alt="Instagram"
                    src="https://www.balicka-clinic.pl/img/instagram.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
