import React, { useState, useCallback } from "react"
import Gallery from "react-photo-gallery"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

import { DATA } from "../components/data"

const TeamPage = () => {
  const [openedImgLink, setOpenedImgLink] = useState(null)
  const [currentImageIndex, setCurrentImageIndex] = useState(null)

  const { team } = DATA

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImageIndex(index)
  }, [])

  const currentImage = team.photos[currentImageIndex]
  const nextImage = team.photos[currentImageIndex + 1]
  const prevImage = team.photos[currentImageIndex - 1]

  return (
    <Layout>
      <SEO
        title={`Zespół`}
        description={`W Balicka Clinic pracujemy z pasją i ogromnym zaangażowaniem. Wyróżnia nas profesjonalizm, wieloletnie lekarskie doświadczenie i empatia.`}
      />

      <div className="team">
        <div className="container">
          <h1 className="offer-item-title">Zespół Balicka Clinic</h1>

          {team.members.map((member, index) => {
            return (
              <React.Fragment key={index}>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <img
                      className="team-member-img"
                      src={member.imgLink}
                      alt={member.title}
                      onClick={() => setOpenedImgLink(member.imgLink)}
                    />
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <p className="team-member-title">{member.title}</p>
                    <p className="team-member-description">
                      {member.description}
                    </p>
                  </div>
                </div>
                <div className="team-separator-line-horizontal"></div>
              </React.Fragment>
            )
          })}
          <div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <Gallery
                  photos={team.photos}
                  onClick={openLightbox}
                  direction={"column"}
                />
              </div>
            </div>
          </div>
          {openedImgLink && (
            <Lightbox
              mainSrc={openedImgLink}
              nextSrc={null}
              prevSrc={null}
              onCloseRequest={() => setOpenedImgLink(null)}
            />
          )}

          {currentImageIndex !== null && (
            <Lightbox
              mainSrc={currentImage?.src}
              nextSrc={nextImage?.src}
              prevSrc={prevImage?.src}
              onMoveNextRequest={() =>
                setCurrentImageIndex(currentImageIndex + 1)
              }
              onMovePrevRequest={() =>
                setCurrentImageIndex(currentImageIndex - 1)
              }
              onCloseRequest={() => setCurrentImageIndex(null)}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default TeamPage
