export const pricing = {
  items: [
    {
      title: "Konsultacje",
      description:
        "Każdy zabieg poprzedzony jest wnikliwą i kompleksową konsultacją lekarską. W pierwszym etapie ustalony zostaje plan terapii i postępowania zabiegowego tak, aby sprostać oczekiwaniom pacjenta zachowując maksymalne bezpieczeństwo.",
      positions: [
        {
          name: "Konsultacja lekarska",
          price: "150 zł",
        },
        {
          name: "Wizyta kontrolna po zabiegu",
          price: "Bezpłatna",
        },
      ],
    },
    {
      title: "Toksyna botulinowa",
      description: `Przez jedną okolice rozumie się: 
        <br/>- Zmarszczki pionowe czoła (lwia bruzda) 
        <br/>- Zmarszczki poziome czoła 
        <br/>- Kurze łapki (wokół oczu) 
        <br/>- Zmarszczki królicze 
        <br/>- Uśmiech dziąsłowy – tzw gummy smile 
        <br/>- Zmarszczki placza (nad górną wargą)`,
      positions: [
        {
          name: "Jedna okolica",
          price: "550 zł",
        },
        {
          name: "Dwie okolice",
          price: "850 zł",
        },
        {
          name: "Trzy okolice",
          price: "1100 zł",
        },
        {
          name: "Leczenie bruksizmu (zgrzytanie zębami)",
          price: "od 1100 zł",
        },
        {
          name: "Leczenie nadpotliwości pach",
          price: "od 1300 zł",
        },
        {
          name: "Leczenie nadpotliwości dłoni",
          price: "od 1300 zł",
        },
        {
          name: "Leczenie nadpotliwości stóp",
          price: "od 1300 zł",
        },
        {
          name: "Leczenie migreny",
          price: "od 1900zł",
        },
      ],
    },
    {
      title: "Mezoterapia igłowa",
      description: `Do mezoterapii stosujemy gotowe preparaty (Neauvia Hydro Deluxe, Filorga NCTF 135HA, Filorga Hydra Booster (NCTF + MHA18), Saypha Rich + Up Grade i inne) albo własną krew pacjenta (osocze lub fibryna)`,
      positions: [
        {
          name: "Mezoterapia gotowymi preparatami (twarz/twarz+szyja)",
          price: "od 700 do 1300 zł",
        },
        {
          name: "Mezoterapia osoczem - twarz",
          price: "900 zł",
        },
        {
          name: "Mezoterapia osoczem - twarz i szyja",
          price: "1100 zł",
        },
        {
          name: "Fibryna - pod oczami",
          price: "700 zł",
        },
        {
          name:
            "Zabieg Cellular Matrix połączenie kwasu hialuronowego i osocza",
          price: "1300 zł",
        },
      ],
    },
    {
      title: "Pakiety zabiegów w Balicka Clinic",
      description: null,
      positions: [
        {
          name: "N lift w Balicka Clinic",
          price: "3700 zł",
        },
        {
          name:
            "Balicka Lifting - połączenie kwasu hialuronowego z zabiegiem Zaffiro i Scarlet RF (zabiegi liczone oddzielnie 6300 zł)",
          price: "5200 zł",
        },
        {
          name:
            "Balicka Princess Lifting - połączenie kwasu hialuronowego, Zaffiro, Scarlet RF oraz nici haczykowych (zabiegi liczone oddzielnie 9300 zł)",
          price: "7400 zł",
        },
      ],
    },
    {
      title: "Okolica Oka",
      description: "Zabiegi sugerujemy wykonać 3 razy",
      positions: [
        {
          name: "Global Eyecon",
          price: "300 zł",
        },
        {
          name: "Sunekos, Light Eyes, Kolagen",
          price: "600 zł",
        },
        {
          name: "Fibryna",
          price: "700 zł",
        },
        {
          name: "Nici Darwin ujędrnienie okolicy oka",
          price: "800 zł",
        },
        {
          name: "Jovelook",
          price: "1000 zł",
        },
      ],
    },
    {
      title: "Wypełniacze",
      description: null,
      positions: [
        {
          name: "Nawilżanie ust",
          price: "1000 zł",
        },
        {
          name: "Powiększanie ust",
          price: "1100 zł",
        },
        {
          name: "Modelowanie kości policzkowych (jarzmowych)",
          price: "od 1000 zł",
        },
        {
          name: "Modelowanie linii żuchwy",
          price: "od 1000 zł",
        },
        {
          name: "Modelowanie nosa",
          price: "1100 zł",
        },
        {
          name: "Wypełnianie linii marionetek i bruzd nosowo wargowych",
          price: "od 1000 zł",
        },
        {
          name: "Zmarszczki palacza",
          price: "1000 zł",
        },
        {
          name: "Dolina łez",
          price: "900 zł",
        },
        {
          name: "Odmłodzenie dłoni",
          price: "1000 zł",
        },
      ],
    },
    {
      title: "Stymulatory",
      description: null,
      positions: [
        {
          name: "Polinuklotydy, Aminokwasy, Kolagen",
          price: "800 - 1200 zł",
        },
        {
          name: "Kwas poli D L/mlekowy Lenisna",
          price: "1500 - 3500 zł",
        },
      ],
    },
    {
      title: "Nici",
      description: null,
      positions: [
        {
          name: "Mono / Screw",
          price: "90 zł / 1 nić",
        },
        {
          name: "Screw",
          price: "120 zł/ 1 nić",
        },
        {
          name: "Barb 4 D- haczykowe",
          price: "400 zł / 1 nić",
        },
        {
          name: "Nici Multi-fill",
          price: "300 zł/ 1 nić",
        },
        {
          name: "Nici Cavern-fill",
          price: "300 zł/ 1 nić",
        },
        {
          name: "Nici Mesh-fill",
          price: "300 zł/ 1 nić",
        },
        {
          name: "Korekta nosa nićmi",
          price: "1500 zł",
        },
        {
          name: "Lifting haczykowy Barb 4D lub Tess Lift",
          price: "3500 - 4500 zł",
        },
      ],
    },
    {
      title: "Lipoliza iniekcyjna",
      description: null,
      positions: [
        {
          name:
            "Podbródek, „boczki”, bryczesy w zależności od ilości preparatu",
          price: "od 600 do 1200 zł",
        },
      ],
    },
    // {
    //   title: "Plamy skórne i przebarwienia",
    //   description: null,
    //   positions: [
    //     {
    //       name:
    //         "Peeling  chemiczny Cosmelan - zabieg + miesięczna kuracja domowa",
    //       price: "1800 zł",
    //     },
    //     {
    //       name: 'Peeling PQ Age  (odświeżenie, zabieg "na wielkie wyjście")',
    //       price: "250 zł",
    //     },
    //     {
    //       name: "Peeling Perfect Peel  - zabieg +  kuracja",
    //       price: "900 zł",
    //     },
    //   ],
    // },
    {
      title: "Dermapen 4 z użyciem kwasów i gotowych preparatów",
      description: null,
      positions: [
        {
          name: "Twarz",
          price: "800 zł",
        },
        {
          name: "Twarz + szyja",
          price: "1000 zł",
        },
        {
          name: "Twarz + szyja + dekolt",
          price: "1200 zł",
        },
        {
          name: "Szyja + dekolt",
          price: "1000 zł",
        },
      ],
    },
    {
      title: "Pen",
      description: null,
      positions: [
        {
          name: "Zabieg mikronakłuwania z użyciem gotowych preparatów",
          price: "300 - 500 zł",
        },
      ],
    },
    {
      title: "Mezoterapia skóry głowy - kuracja przeciw wypadaniu włosów",
      description: null,
      positions: [
        {
          name: "Dr Cyj",
          price: "700 zł",
        },
        {
          name: "Hair plus, osocze, fibryna, polinukleotydy",
          price: "700 zł",
        },
      ],
    },
    {
      title: "Zaffiro",
      description: null,
      positions: [
        {
          name: "Twarz",
          price: "800 zł",
        },
        {
          name: "Twarz + szyja",
          price: "1000 zł",
        },
        {
          name: "Twarz + szyja + dekolt",
          price: "1200 zł",
        },
        {
          name: "Brzuch/uda, inne okolice cena",
          price: "indywidualna",
        },
      ],
    },
    {
      title: "Zabiegi laserowe",
      description: null,
      positions: [
        {
          name: "Leczenie blizn, resurfacing - Laser CO2",
          price: "1400-1800 zł",
        },
      ],
    },
    {
      title: "Scarlet RF",
      description: null,
      positions: [
        {
          name: "Twarz",
          price: "1900 zł",
        },
        {
          name: "Twarz + szyja",
          price: "2100 zł",
        },
        {
          name: "Twarz + szyja + dekolt",
          price: "2300 zł",
        },
      ],
    },
    {
      title: "Laser tulowy",
      description: null,
      positions: [
        {
          name:
            "Baby Face – efekt skóry niemowlaka (twarz/szyja/dekolt/okolice oczu/dłonie/plecy) - 1 obszar",
          price: "1500 zł",
        },
        {
          name:
            "Baby Face – efekt skóry niemowlaka (twarz/szyja/dekolt/okolice oczu/dłonie/plecy) - 2 obszary",
          price: "1800 zł",
        },
        {
          name:
            "Baby Face – efekt skóry niemowlaka (twarz/szyja/dekolt/okolice oczu/dłonie/plecy) - 3 obszary",
          price: "2200 zł",
        },
        {
          name: "Laserowe leczenie wypadania włosów - pojedynczy zabieg",
          price: "700 zł",
        },
        {
          name: "Laserowe leczenie wypadania włosów - pakiet (3x)",
          price: "1700 zł",
        },
        {
          name: "Blizna/ograniczony obszar",
          price: "300 - 500 zł",
        },
      ],
    },
    {
      title: "Laser frakcyjny nieablacyjny - laser frakcyjny młodości",
      description: null,
      positions: [
        {
          name: "Twarz",
          price: "900 zł",
        },
        {
          name: "Twarz + szyja",
          price: "1100 zł",
        },
        {
          name: "Twarz + szyja + dekolt",
          price: "1300 zł",
        },
        {
          name: "Pojedynczy fragment (np blizny)",
          price: "200 - 500 zł",
        },
      ],
    },
    {
      title: "Laser naczyniowy",
      description: null,
      positions: [
        {
          name: "Cała twarz",
          price: "800 - 1000 zł",
        },
        {
          name: "Twarz + szyja + dekolt",
          price: "1200 zł",
        },
        {
          name: "Nos naczynka",
          price: "300 - 500 zł",
        },
        {
          name: "Policzki",
          price: "500 - 800 zł",
        },
        {
          name: "Kolejny zabieg minus 20%",
          price: "",
        },
      ],
    },
    {
      title: "Laser pikosekundowy - odmładzanie laserowe, stymulacja skóry",
      description: null,
      positions: [
        {
          name: "Laser pikosekundowy",
          price: "1700-2300 zł",
        },
      ],
    },
    {
      title: "Laser pikosekundowy - laserowe usuwanie tatuażu – czarny/kolor",
      description: null,
      positions: [
        {
          name: "Tatuaż bardzo mały czarny do 4 cm2",
          price: "od 600 zł",
        },
        {
          name: "Tatuaż mały do 25 cm2",
          price: "1000 zł",
        },
        {
          name: "Tatuaż średni do 150 cm2",
          price: "1400 zł",
        },
        {
          name: "Tatuaż średni większy do 300 cm2",
          price: "2200 zł",
        },
        {
          name: "Tatuaż duży do 600 cm2",
          price: "2750 zł",
        },
        {
          name: "Tatuaż bardzo duży powyżej 600 cm2",
          price: "od 3800 zł",
        },
      ],
    },
    {
      title: "Laser pikosekundowy - usuwanie makijażu permanentnego",
      description: null,
      positions: [
        {
          name: "Usta",
          price: "600 zł",
        },
        {
          name: "Eyeliner góra/dół",
          price: "600 zł",
        },
        {
          name: "Brwi",
          price: "400 zł",
        },
      ],
    },
    {
      title: "Laser pikosekundowy - usuwanie przebarwień",
      description: null,
      positions: [
        {
          name: "Pojedyncza zmiana barwnikowa",
          price: "200 - 600 zł",
        },
        {
          name: "Twarz",
          price: "1700 zł",
        },
      ],
    },
    {
      title: "Laser pikosekundowy - leczenie blizn",
      description: null,
      positions: [
        {
          name: "Blizna",
          price: "300 - 800 zł",
        },
        {
          name: "Blizny potrądzikowe twarz",
          price: "2000 zł",
        },
      ],
    },
    {
      title: "Laser pikosekundowy - laserowa redukcja rozstępów",
      description: null,
      positions: [
        {
          name: "Pojedynczy zabieg",
          price: "od 2250 zł",
        },
      ],
    },
    {
      title: "Zabiegi redukujące przebarwienia oraz zmiany trądzikowe",
      description: "",
      positions: [
        {
          name: "Cosmelan/Dermamelan (terapia na 3 mce)",
          price: "1700/1900 zł",
        },
        {
          name: "PRX T33/PQ Age - twarz/szyja /dekolt (1 zabieg)",
          price: "400 zł",
        },
        {
          name: "Peelingi kwasowe na skórę ze zmianami trądzikowymi (1 zabieg)",
          price: "300 - 500 zł",
        },
      ],
    },
    {
      title: "Podologia",
      description: null,
      positions: [
        {
          name: "Konsultacja",
          price: "150 zł",
        },
        {
          name: "Kontrola",
          price: "100 zł",
        },
        {
          name: "Podstawowy zabieg podologiczny",
          price: "160 zł",
        },
        {
          name: "Usunięcie hiperkeratoz",
          price: "120 zł",
        },
        {
          name: "Opracowanie paznokci",
          price: "120 zł",
        },
        {
          name: "Wycięcie wrastającego paznokcia",
          price: "od 150 - 190 zł",
        },
        {
          name: "Klamra ortonyksyjna",
          price: "220 zł",
        },
        {
          name: "Przełożenie klamry",
          price: "100 zł",
        },
        {
          name: "Usunięcie odcisku",
          price: "140 zł",
        },
        {
          name: "Usunięcie brodawki",
          price: "100 zł",
        },
        {
          name: "Opracowanie rany cukrzycowej",
          price: "100 zł",
        },
        {
          name: "Ortoza indywidualna",
          price: "80 zł",
        },
        {
          name: "Opr.chorobowo zmienionych paznokci",
          price: "150 zł",
        },
        {
          name: "Rekonstrukcja płytki paznokciowej",
          price: "150 zł",
        },
        {
          name: "Preparat pielęgnacyjny",
          price: "od 45 do 85 zł",
        },
        {
          name: "Badanie mykologiczne",
          price: "150 zł",
        },
      ],
    },
    {
      title: "Dermatologia",
      description: "",
      positions: [
        {
          name: "Konsultacja dermatologiczna",
          price: "200 zł",
        },
        {
          name: "Wizyta kontrolna",
          price: "150 zł",
        },
        {
          name: "Dermatoskopia + konsultacja dermatologiczna",
          price: "350 zł",
        },
        {
          name: "Dermatoskopia (od 1 do 3 znamion)",
          price: "150 zł",
        },
        {
          name: "Dermatoskopia (od 5 znamion)",
          price: "250 zł",
        },
        {
          name: "Histopatologia zmian skórnych / biopsja skóry",
          price: "100 - 200 zł",
        },
      ],
    },
    {
      title: "Oczyszczanie wodorowe",
      description: null,
      positions: [
        {
          name: "Oczyszczanie wodorowe",
          price: "200 - 290 zł",
        },
        {
          name: "Oczyszczanie wodorowe + kwasy (trądzik, przebarwienia)",
          price: "400 zł",
        },
      ],
    },
    {
      title: "Naświetlanie lampą LED",
      description: null,
      positions: [
        {
          name: "Naświetlanie lampą LED",
          price: "80 zł",
        },
        {
          name: "Naświetlanie lampą LED (pakiet 5 zabiegów)",
          price: "350 zł",
        },
      ],
    },
    {
      title: "Bloomea",
      description: null,
      positions: [
        {
          name:
            "Zabieg - w zależności od protokołu (możliwość łączenia z innymi zabiegami)",
          price: "350 - 500 zł",
        },
      ],
    },
  ],
}
