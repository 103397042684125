import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Termolifting`}
        description={`Zabieg Termolifting przeznaczony jest dla tych, którzy chcą szybko i skutecznie ujędrnić skórę i zminimalizować zmarszczki grawitacyjne bez zastosowania metod inwazyjnych.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Termolifting</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Wykorzystuje ciepło promieniowania podczerwonego Termolifting to
                urządzenie włoskiej firmy Estelougue przeznaczone do cieplnego
                liftingu skóry. Przeznaczony dla tych, którzy chcą szybko i
                skutecznie ujędrnić skórę i zminimalizować zmarszczki
                grawitacyjne bez zastosowania metod inwazyjnych.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Zasady działania Termoliftingu</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Urządzenie emitując promieniowanie podczerwone IR o specjalnie
                dobranej długości fali (750-1800 nm) powoduje jednolite i
                stopniowe rozgrzanie głębokich warstw skóry właściwej (65°C) .
                Wytwarzane ciepło , powoduje podgrzanie włókien kolagenowych,
                powodując ich kurczenie się do pierwotnej długości. W efekcie
                skóra zostaje zagęszczona i ściągnięta, zwiększa się jej
                napięcie oraz elastyczność, zmianom ulega również jej struktura,
                zmarszczki zostają wygładzone, co widać już po paru chwilach od
                rozpoczęcia zabiegu.
                <br />
                Drugim, długofalowym skutkiem, jest pobudzenie i stymulowanie
                fibroblastów do produkcji nowych włókien kolagenowych.
                Zwiększona zostaje o około 30 procent produkcja kolagenu, co
                daje efekt poprawy gęstości skóry i liftingu. Proces tworzenia
                się kolagenu następuje stopniowo, stąd końcowy efekt odmłodzenia
                skóry uzyskujemy 3-6 miesięcy po zabiegu.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Dla kogo?</p>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>
                    dla Pań z wiotką skóry na twarzy, szyi i dekolcie  w wyniku
                    procesu starzenia lub po odchudzaniu
                  </li>
                  <li>
                    wiotka skóra na ciele w wyniku procesu starzenia lub utraty
                    dużej masy ciała (brzuch, wewnętrzne partie ud i ramion,
                    skóra nad kolanami)
                  </li>
                  <li>poprawa wyglądu skóry dekoltu i piersi</li>
                  <li>obkurczenie skóry na brzuchu u kobiet po porodzie</li>
                  <li>bruzdy nosowo-wargowe, zmarszczki</li>
                  <li>poprawa owalu twarzy</li>
                  <li>opadające policzki</li>
                </ul>
                Zabieg polecany jest profilaktycznie dla osób między 25-35
                rokiem życia (1 zabieg). Seria zabiegów na twarz (około 4)
                przeznaczona jest dla osób po 35. roku życia.
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Jak wygląda zabieg?</p>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Pierwsza faza zabiegu Termolifting rozpoczyna się schłodzeniem skóry
                w celu przygotowania jej do działania promieniowania IR i
                ochrony naskórka przed poparzeniem. Następnie, zawarty w skórze
                właściwej kolagen, zostaje podgrzany przy użyciu specjalnej,
                szafirowej głowicy emitującej podczerwień. Ponowne schłodzenie
                równoważy obszar zabiegowy zgodnie z zasadą zimno-ciepło-zimno.
                Druga faza zabiegu obejmuje łagodzący i relaksujący miejscowy
                masaż coolerem o temperaturze chłodzenia 0-20°C.
              </p>
              <div className="offer-item-description">
                Częstotliwość wykonywania zabiegów:
                <ul>
                  <li>
                    profilaktycznie dla osób między 25-35 rokiem życia (1
                    zabieg).
                  </li>
                  <li>
                    seria ok. 4 – 6 zabiegów wykonywanych w miesięcznych
                    odstępach jest dla osób po 35. roku życia
                  </li>
                  <li>rekomenduje się powtórzenie sesji co 1,5 roku.</li>
                </ul>
              </div>
              <p className="offer-item-description">
                Skóra po zabiegu wygląda naturalnie. Bezpieczeństwo zapewnia
                zaawansowany system chłodzący. Jedynie w nielicznych przypadkach
                występuje minimalne zaczerwienienie, które po kilku godzinach
                ustępuje.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Przeciwwskazania</p>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>Terapia retinoidami</li>
                  <li>Otwarte rany</li>
                  <li>Ciąża i okres karmienia piersią</li>
                  <li>Opryszczka</li>
                  <li>Choroby nowotworowe i autoimmunologiczne</li>
                  <li>Implanty</li>
                  <li>Choroby skóry</li>
                  <li>Świeża opalenizna</li>
                  <li>
                    Samoopalacz, kremy brązujące (odstawić 4 tygodnie przed
                    zabiegiem)
                  </li>
                  <li>
                    Okres dwóch tygodni od zastosowania wypełniaczy i toksyny
                    botulinowej (botoks, restylane itp.)
                  </li>
                  <li>Bielactwo, łuszczyca</li>
                  <li>Złuszczanie kwasami (4 tygodnie przed zabiegiem) </li>
                </ul>
                * Aktywna opryszczka oraz każdy stan zapalny organizmu jest
                przeciwwskazaniem do wykonania zabiegu
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
