import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Pakiet Ślubny`}
        description={`Balicka Clinic wychodzi do Was drogie Panie z niesamowitą ofertą „Pakiet Panny młodej”, oferujemy Wam kompleksowe przygotowanie Waszej skóry, aby stała się promienna, świetlista, napięta i dobrze nawilżona.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Pakiet Panny Młodej</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Dla każdej z nas ślub jest najpiękniejszym dniu w życiu.
                Wszystko planujemy w najdrobniejszych szczegółach, pragniemy
                wyglądać olśniewająco, korzystamy z usług fryzjerki,
                makijażystki, manikiurzystki, wybieramy suknię podkreślającą
                naszą figurę... często jednak w ferworze przygotowań zapominamy
                o jakości naszej skóry. Wraz ze stresem jak na złość pojawiają
                się pryszcze, rozszerzają pory, skóra traci koloryt i jest
                przesuszona.
              </p>
              <p className="offer-item-description">
                Balicka Clinic wychodzi do Was drogie Panie z niesamowitą ofertą
                „Pakiet Panny młodej”, oferujemy Wam kompleksowe przygotowanie
                Waszej skóry, aby stała się promienna, świetlista, napięta i
                dobrze nawilżona. Dodatkowo możemy podkreślić i nawilżyć Wasze
                usta, aby były lekko wydęte, soczyste i pięknie podkreślały Wasz
                uśmiech.
              </p>
              <div className="offer-item-description">
                Przygotowania do ślubu należy zacząć min. miesiąc wcześniej! W
                pakiecie otrzymacie Panie ofertę zabiegową w bardzo atrakcyjnej
                cenie, dużo tańszej niż kupowane pojedynczo zabiegi:
                <ul>
                  <li>
                    zabieg Mikronakłuwania z peelingami rozjaśniającym i
                    usuwającym przebarwienia (3-4 tyg przed)
                  </li>
                  <li>
                    mezoterapia osoczem / lub innym preparatem nawilżającym po
                    konsultacji lekarza (2 tyg przed)
                  </li>
                  <li>nawilżenie ust (3 tyg przed)</li>
                  <li>korekta kurzych łapek (botoks) 2 tyg przed</li>
                </ul>
              </div>
              <p className="offer-item-description">
                Osobno wycenione zabieg na ponad 3 tys. – w pakiecie „Panny
                młodej” – 2.5tys!
              </p>
            </div>
            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Pakiet Mama Panny Młodej</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Chcielibyśmy także zadbać o Wasze mamy! Dla nich również
                oferujemy fantastyczne pakiety ślubne, aby także czuły się
                wyjątkowo piękne w dniu Waszego Święta, wszak to one będą czuwać
                nad przebiegiem ceremonii. Oczywiście nasze mamy z racji
                dojrzałego wieku powinny zacząć swoje przygotowania dwa miesiące
                przed ceremonią. A co proponujemy dla Mam?
              </p>
              <div className="offer-item-description">
                <ul>
                  <li>
                    zabieg Balicka Glow Look (autorski zabieg rewitalizacji
                    kwasem hialuronowym) (2 mce prze ślubem) / lub mezoterapia
                    hydro połączona z zabiegiem Termolifting (miesiąc przed)
                  </li>
                  <li>botoks</li>
                  <li>
                    termolifting (zabieg bankietowy wszystkich celebrytek,
                    dający natychmiastowy efekt Glam look) 1-2 dni przed
                    ceremonią
                  </li>
                </ul>
              </div>
              <p className="offer-item-description">
                Osobno wycenione zabiegi na 3.5 tys. – w pakiecie „Mama Panny
                młodej” – 3tys!
              </p>
            </div>
            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Pakiet Pana Młodego</h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>zabieg oczyszczający Water peeling</li>
                  <li>
                    Termolifting / lub botoks wybranej okolicy (1-2 tyg
                    wcześniej)
                  </li>
                  <li>
                    zabieg botoksem na nadpotliwość (pachy, dłonie…aby Pan młody
                    w tym ważnym dniu nie musiał denerwować się plamami potu na
                    koszuli albo wilgotnymi dłońmi? (3 tyg wcześniej)
                  </li>
                </ul>
              </div>

              <p className="offer-item-description">
                Zabiegi wycenione osobno na 2 tys. - w pakiecie „Pana młodego”
                1500 zł!
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Zapraszamy serdecznie!</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
