import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Oczyszczanie wodorowe skóry`}
        description={`Innowacyjna procedura zabiegowa wykonywana w Balicka Clinic przy użyciu nowoczesnego urządzenia HYDRANOVA.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Oczyszczanie wodorowe skóry</h1>
              <div className="separator-line-horizontal"></div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">
                Na czym polega zabieg HYDRANOVA?
              </h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Generator wodoru w urządzeniu jest produkcji koreańskiej, działa
                od momentu włączenia urządzenia, utrzymanie stałej temperatury
                wody między 35 a 40 stopni Celsjusza sprawia, że elektroliza
                wody zachodzi na stabilnym i stałym poziomie, a oczyszczanie
                skóry z sebum i tłuszczów jest równomierne i niezwykle
                skuteczne.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Efekty zabiegu</p>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>normalizacja wydzielania sebum</li>
                  <li>usunięcie widocznych zaskórników</li>
                  <li>blask i zdrowy koloryt</li>
                  <li>nawilżenie</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Cechy urządzenia</p>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                <strong>Głowica plazmy</strong>
                <br />
                Dezynfekuje skórę, usuwa bakterie, grzyby, wirusy, a także
                roztocza. Działa antyseptycznie, uspokaja reaktywną i
                podrażnioną skórę. Przyczynia się także do szybszego gojenia się
                ran, stanów zapalnych i zwężania porów skóry.
              </p>
              <p className="offer-item-description">
                <strong>Głowica bio prądów</strong>
                <br />
                Prądy wykazują silne działanie antyoksydacyjne, usuwają metale
                ciężkie z powierzchni skóry, a także wprowadzają składniki
                aktywne z serum w głębokie warstwy skóry. Prądy wykonują
                mikromasaż, relaksują mięśnie, a także poprawiają ich napięcie.
              </p>
              <p className="offer-item-description">
                <strong>Głowica oczyszczania wodorowego</strong>
                <br />
                Zastosowanie nowoczesnej technologii obiegu podciśnieniowego
                wody usuwa martwy naskórek i dogłębnie oczyszcza Głowica plazmy
                skórę na poziomie komórkowym.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Zdjęcia</p>
              <div className="separator-line-horizontal"></div>
              <div class="offer-item-images">
                <img
                  src="../img/offer/oczyszczanie-wodorowe-skory/images/1.jpg"
                  alt="Oczyszczanie wodorowe skóry HYDRANOVA"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/oczyszczanie-wodorowe-skory/images/2.jpg"
                  alt="Oczyszczanie wodorowe skóry HYDRANOVA"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/oczyszczanie-wodorowe-skory/images/3.jpg"
                  alt="Oczyszczanie wodorowe skóry HYDRANOVA"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/oczyszczanie-wodorowe-skory/images/4.jpg"
                  alt="Oczyszczanie wodorowe skóry HYDRANOVA"
                  className="offer-item-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
