import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Mikronakłuwanie`}
        description={`Mikronakłuwanie dzięki systemowi pulsujących igieł ułatwiającemu dostarczanie substancji czynnych do naskórka i skóry właściwej zapewnia niezrównaną stymulację fibroblastów.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">
                Mikronakłuwanie - naturalne procesy regeneracyjne
              </h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                dzięki systemowi pulsujących igieł ułatwiającemu dostarczanie
                substancji czynnych do naskórka i skóry właściwej zapewnia
                niezrównaną stymulację fibroblastów. Powstałe mikrourazy skóry
                wykorzystują naturalne zdolności organizmu oraz wrodzoną
                zdolność naprawy skóry do fizjologicznej indukcji kolagenu.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Działanie</h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>
                    stymulacja produkcji kolagenu, elastyny i kwasu
                    hialuronowego
                  </li>
                  <li>
                    stymulacja fibroblastów poprzez działanie czynników wzrostu
                    pochodzenia płytkowego
                  </li>
                  <li>
                    poprawa gęstości i elastyczności oraz odżywienia skóry
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Precyzja zabiegu</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Precyzyjnie dobrana głębokość iniekcji (w zakresie od 0,2 mm do
                3 mm) pozwala na pełną kontrolę wykonywanego zabiegu – inaczej
                niż w przypadku derma-roller’ów i mezoterapii wykonywanej przy
                użyciu strzykawki i igły.
                <br />
                Dzięki opatentowanej technologii pulsujących igieł nasze
                urządzenie medyczne do mikro nakłuwania jest bardziej efektywny
                i bezpieczny niż znane dotąd metody stosowane w mikro nakłuwaniu
                skóry. Zabieg z zastosowaniem tego urządzenia znacząco skraca
                również czas leczenia i powrotu pacjenta do codziennych
                czynności.
                <br />
                Dzięki zabiegowi mikro nakłuwania widoczne jest spłycenie
                zmarszczek, efekt pogrubienia skóry oraz naturalna stymulacja
                produkcji kolagenu. Ponadto zmniejszenie widoczności porów,
                redukcja widoczności rozstępów czy blizn potrądzikowych i blizn
                oparzeniowych. Urządzenie stosowane jest także w leczeniu
                łysienia.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Obszary terapii</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description"></p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h3 className="offer-item-title">
                Leczenie najtrudniejszych obszarów
              </h3>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Nasze urządzenie do nakłuwania jest bardzo wszechstronnym
                narzędziem, dlatego zabiegi przy jego użyciu można wykonywać na
                całej twarzy, szyi, dekolcie, brzuchu, udach, pośladkach czy
                skórze głowy. Dzięki niewielkim rozmiarom możliwe jest
                wykonywanie zabiegów na nawet najtrudniej dostępnych obszarach,
                jak okolice oczu czy ust. • WSKAZANIA
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h3 className="offer-item-title">
                Urządzenie do zadań specjalnych
              </h3>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Mnogość wskazań terapeutycznych i znakomite efekty czynią z
                niego narzędzie do zadań specjalnych. Jest ono pierwszą linia
                walki z oznakami fotostarzenia, wiotką skórą pozbawioną
                witalności, skórą skłonną do trądziku i bliznami potrądzikowymi,
                przebarwieniami, rozstępami i łysieniem. • DZIAŁANIE
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Stymulacja fibrobalstów</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Dzięki systemowi pulsujących igieł ułatwiającemu dostarczanie
                substancji czynnych do naskórka i skóry właściwej zapewnia
                niezrównaną stymulację fibroblastów. Powstałe mikrourazy skóry
                wykorzystują naturalne zdolności organizmu oraz wrodzoną
                zdolność naprawy skóry do fizjologicznej indukcji kolagenu. •
                BEZPIECZEŃSTWO
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Molibdenowe igły</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Wymienne, jednorazowe kartridże wyposażone są w 16 molibdenowych
                igieł o grubości 33G. Igły nie zawierają chromu i niklu dzięki
                czemu zabieg można wykonać również u osób uczulonych na te
                metale. Molibden uznawany jest za najbardziej zaawansowany
                materiał służący do produkcji narzędzi chirurgicznych. Dzięki
                jego zastosowaniu igły w naszym urządzeniu są niezwykle cienkie
                i wytrzymałe co znacznie poprawia komfort pacjenta podczas
                zabiegu.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Zdjęcia</p>
              <div className="separator-line-horizontal"></div>
              <div class="offer-item-images">
                <img
                  src="../img/offer/mikronakluwanie/images/1.jpg"
                  alt="Zabieg mikronakłuwanie"
                  className="offer-item-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
