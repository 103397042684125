import PropTypes from "prop-types"
import React from "react"
import Navbar from "react-bootstrap/lib/Navbar"
import Nav from "react-bootstrap/lib/Nav"
import NavItem from "react-bootstrap/lib/NavItem"

const Header = ({ siteTitle }) => (
  <header>
    <Navbar className="navbar" collapseOnSelect>
      <div className="container">
        <Navbar.Header className="navbar-brand-container">
          <Navbar.Brand className="navbar-brand-container">
            <a className="navbar-brand" href="/">
              <img src="https://www.balicka-clinic.pl/img/logo.svg" alt="logo" className="navbar-logo" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav className="navbar-items">
            <NavItem eventKey={1} href="/oferta">
              OFERTA
            </NavItem>
            <NavItem eventKey={2} href="/cennik">
              CENNIK
            </NavItem>
            <NavItem eventKey={3} href="/metamorfozy">
              METAMORFOZY
            </NavItem>
            <NavItem eventKey={4} href="/o-nas">
              O NAS
            </NavItem>
            <NavItem eventKey={5} href="/zespol">
              ZESPÓŁ
            </NavItem>
            <NavItem eventKey={6} href="/kontakt">
              KONTAKT
            </NavItem>
            <NavItem eventKey={7} href="/zapisy">
              ZAPISY
            </NavItem>
            <NavItem eventKey={8} href="/blog">
              BLOG
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
